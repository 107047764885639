import { useEffect, useRef } from 'react';
import Lottie from 'lottie-react';
import { useAnimate, useInView } from 'framer-motion';
import type { SectionHowPortalWorks, CardFlatCard } from '@eva/cms-types';

import { getStringData } from '../../lib/strapi';
import howItWorks from '../../lottie/how-it-works.json';

interface Props {
  data: SectionHowPortalWorks['attributes'];
}

interface CardProps {
  order: number;
  card: CardFlatCard['attributes'];
}

const createAnimationOrderId = (order: number) =>
  `how_portal_work_animation-${order}`;

function HowPortalWorksCard(props: CardProps) {
  const { title, description } = getStringData(props.card);
  return (
    <div
      className="opacity-0 flex flex-col"
      id={createAnimationOrderId(props.order)}
    >
      <p className="text-title">{`0${props.order}`}</p>
      <h4 className="text-h4 mb-2 mt-4 min-h-[4.5rem]">{title}</h4>
      <p className="text-body3">{description}</p>
    </div>
  );
}

function HowPortalWorksAnimation(props: Props) {
  const { title } = getStringData(props.data);

  const ref = useRef(null);
  const [, animate] = useAnimate();
  const isInView = useInView(ref, { once: true });

  useEffect(() => {
    if (isInView) {
      const enterAnimation = async () => {
        const getCardElement = (order: number) =>
          document.getElementById(createAnimationOrderId(order)) as HTMLElement;
        for (let i = 0; i <= 4; i++) {
          await animate(getCardElement(i), { opacity: 1 }, { duration: 0.5 });
        }
      };
      enterAnimation();
    }
  }, [isInView]);

  const cards = props.data.cards as unknown as CardFlatCard['attributes'][];

  return (
    <div className="flex flex-col desktop:grid grid-cols-4 grid-rows-2 gap-y-16 rounded-4xl py-6 pb-12 desktop:py-16 px-8 desktop:px-20 bg-white shadow-3xl">
      <div
        id={createAnimationOrderId(0)}
        className="opacity-0 col-span-2 row-span-2 order-0 desktop:order-2 flex justify-center items-center relative"
      >
        <Lottie
          loop
          animationData={howItWorks}
          className="w-[21.313rem] desktop:w-[37.5rem] h-[21.313rem] desktop:h-[37.5rem]"
        />
        <h2
          ref={ref}
          className="text-h4 desktop:text-h2 max-w-[10rem] desktop:max-w-[20.25rem] absolute text-center"
        >
          {title}
        </h2>
      </div>
      {cards.map((card, index) => {
        const finalOrder = index === 0 ? 1 : index + 2;
        return (
          <div key={index} style={{ order: finalOrder }}>
            <HowPortalWorksCard card={card} order={index + 1} />
          </div>
        );
      })}
    </div>
  );
}

export default HowPortalWorksAnimation;
